import { useEffect } from 'react';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);

  const listener = event => {
    if (!ref.current || !ref.current.contains(event.target)) {
        handler && handler(event);
    }
  };
}

export default useOnClickOutside;
