import { CreateLogo } from '../components/icons';
import Button from '../components/Button';
import styled from 'styled-components';
import { theme } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-size: 24px;
  line-height: 24px;
  color: #14142a;
  margin: 10% 0 6%;
  display: flex;
  justify-content: center;
`;

const NoJobsWrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: calc(100vh - 60px);
  align-items: center;
`;
const NoJobs = () => {
  const navigate = useNavigate();

  const navigateToCreate = () => {
    navigate('/create-job');
  };
  return (
    <NoJobsWrapper>
      <CreateLogo />
      <Title>No job adverts yet</Title>
      <Button
        clickHandler={navigateToCreate}
        color={theme.color}
        bg={theme.primaryBlue}
        padding="10px 20px"
        width="400px">
        Create JD
      </Button>
    </NoJobsWrapper>
  );
};

export default NoJobs;
