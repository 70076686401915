import React from 'react';
import PropTypes from 'prop-types';

const MapIcon = ({ className, fill }) => {
  const styles = { fill: fill || '#4E4B66' };
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.78739 3.03765C4.77201 2.05303 6.10743 1.49988 7.49989 1.49988C8.89235 1.49988 10.2278 2.05303 11.2124 3.03765C12.197 4.02226 12.7502 5.35769 12.7502 6.75015C12.7502 8.1426 12.197 9.47803 11.2124 10.4626L7.49989 14.1751L3.78739 10.4626C3.29983 9.97513 2.91307 9.39635 2.6492 8.75936C2.38532 8.12236 2.24951 7.43963 2.24951 6.75015C2.24951 6.06066 2.38532 5.37793 2.6492 4.74093C2.91307 4.10394 3.29983 3.52516 3.78739 3.03765V3.03765ZM7.49989 8.25015C7.89772 8.25015 8.27925 8.09211 8.56055 7.81081C8.84186 7.5295 8.99989 7.14797 8.99989 6.75015C8.99989 6.35232 8.84186 5.97079 8.56055 5.68949C8.27925 5.40818 7.89772 5.25015 7.49989 5.25015C7.10207 5.25015 6.72054 5.40818 6.43923 5.68949C6.15793 5.97079 5.99989 6.35232 5.99989 6.75015C5.99989 7.14797 6.15793 7.5295 6.43923 7.81081C6.72054 8.09211 7.10207 8.25015 7.49989 8.25015Z"
        fill="black"
        style={styles}
      />
    </svg>
  );
};

MapIcon.defaultProps = {
  className: '',
  fill: '',
};
MapIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default MapIcon;
