import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import styled from 'styled-components';

const ModalElement = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = ({ children }) => {
  return <>{ReactDOM.createPortal(<ModalElement>{children}</ModalElement>, document.body)}</>;
};

Modal.propTypes = {
  children: PropTypes.node,
  closeHandler: PropTypes.func,
};

export default Modal;
