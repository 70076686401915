import React from 'react';
import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import './style.css';

const QuillEditor = ({ handleEditorMsg, content, className }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(content);
    handleEditorMsg(content);
  }, [content]);

  const onChange = value => {
    setValue(value);

    handleEditorMsg(value);
  };
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      className={className}
      placeholder=""
    />
  );
};

QuillEditor.defaultProps = {
  className: '',
};
QuillEditor.propTypes = {
  handleEditorMsg: PropTypes.func,
  content: PropTypes.string,
  className: PropTypes.string,
};
export default QuillEditor;
