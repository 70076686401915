import axios from 'axios';
const jobsAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
});

jobsAPI.interceptors.request.use(config => {
  if (config['Content-Type'] === 'multipart/form-data') {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  const baseURL = config.baseURL;
  const accountId = localStorage.getItem('accountid');
  config.baseURL = `${baseURL}/api/v1/accounts/${accountId}/personalized`;

  const token = localStorage.getItem('token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

const createJob = async job => {
  try {
    const result = await jobsAPI.post('/jobs', job);
    if (result?.status === 200) {
      return { msg: result?.data?.msg, job: result?.data?.data };
    }
    return result?.data?.data;
  } catch (error) {
    console.log('Error in Create JD', error);
    return error;
  }
};

const duplicateJob = async jobId => {
  try {
    const result = await jobsAPI.post(`/jobs/duplicate-job/${jobId}`);
    return result;
  } catch (error) {
    console.log('Error in duplicate job');
  }
};
const getAllJobs = async ({ searchTitle, currentPage, limit, isActive }) => {
  try {
    const result = await jobsAPI.get(
      `/jobs?name=${searchTitle}&limit=${limit}&page=${
        !searchTitle ? currentPage + 1 : 1
      }&isActive=${isActive}`
    );

    // console.log('After List jobs', result);
    return result.data.data;
  } catch (error) {
    console.log(error);
  }
};

const getSingleJob = async id => {
  return await jobsAPI.get(`/jobs/${id}`);
};

const uploadJD = async file => {
  return await jobsAPI.post('/jobs/upload-jd', file);
};

const deleteJob = async value => {
  try {
    const result = await jobsAPI.delete(`/jobs/${value}`);

    return result;
  } catch (error) {
    console.log('Error in Delete Jd', error);
    return error;
  }
};
const updateJD = async ({ jobId, jdObj }) => {
  console.log('Updating job', jdObj, jobId);
  return await jobsAPI.put(`/jobs/${jobId}`, jdObj);
};

// Public Routes or Third Party APIs
const getLogo = async name => {
  try {
    const res = await axios.get(
      `https://autocomplete.clearbit.com/v1/companies/suggest?query=${name}`
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

const landingPage = async jobId => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/accounts/personalized/preview?id=${jobId}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Gets the JD page link for the given parameters
 * @param {String} jdId - JD id
 * @param {String} candidateName - Candidate name
 * @param {String} candidateImageUrl - Candidate image url (optional)
 * @returns String
 */
const getJdLink = async (jdId, candidateName, candidateImageUrl = '') => {
  if (!jdId || !candidateName) return '';
  try {
    const response = await jobsAPI.post(`/generate-jd`, {
      id: jdId,
      candidateName,
      ...(candidateImageUrl && {
        candidateImage: candidateImageUrl,
      }),
    });
    if (response?.status !== 200) return '';
    return `${process.env.REACT_APP_BACKEND_URL}/api/v1/accounts/personalized/preview?id=${response?.data?.data?.id}`;
  } catch (error) {
    console.log(error);
    return '';
  }
};

const checkIfNameExist = async jobTitle => {
  try {
    const result = await jobsAPI.get(`/jobs/name-exists/${jobTitle}`);
    return result?.data;
  } catch (error) {
    console.log('Error in checking name of Jd', error);
    return error;
  }
};

export {
  createJob,
  getAllJobs,
  getSingleJob,
  uploadJD,
  deleteJob,
  updateJD,
  getLogo,
  landingPage,
  checkIfNameExist,
  getJdLink,
  duplicateJob,
};
