import React from 'react';

const DeleteTemplate = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.36612 0.616116C4.60054 0.381696 4.91848 0.25 5.25 0.25H7.75C8.08152 0.25 8.39946 0.381696 8.63388 0.616116C8.8683 0.850537 9 1.16848 9 1.5V2.75H11.5C11.8452 2.75 12.125 3.02982 12.125 3.375C12.125 3.72018 11.8452 4 11.5 4H11.457L10.9565 11.0081C10.9565 11.0082 10.9566 11.0081 10.9565 11.0081C10.9228 11.4811 10.7112 11.9239 10.3642 12.2471C10.0171 12.5703 9.56048 12.75 9.08624 12.75H3.91376C3.43952 12.75 2.98289 12.5703 2.63584 12.2471C2.28883 11.9239 2.07717 11.4813 2.04346 11.0083C2.04346 11.0082 2.04347 11.0083 2.04346 11.0083L1.54304 4H1.5C1.15482 4 0.875 3.72018 0.875 3.375C0.875 3.02982 1.15482 2.75 1.5 2.75H4V1.5C4 1.16848 4.1317 0.850537 4.36612 0.616116ZM5.25 2.75H7.75V1.5H5.25V2.75ZM2.79622 4L3.29029 10.9192C3.30151 11.0769 3.37208 11.2246 3.48776 11.3324C3.60344 11.4401 3.75565 11.5 3.91375 11.5H9.08627C9.24435 11.5 9.39656 11.4401 9.51224 11.3324C9.62792 11.2246 9.69848 11.0771 9.7097 10.9194L10.2038 4H2.79622ZM5.25 5.25C5.59518 5.25 5.875 5.52982 5.875 5.875V9.625C5.875 9.97018 5.59518 10.25 5.25 10.25C4.90482 10.25 4.625 9.97018 4.625 9.625V5.875C4.625 5.52982 4.90482 5.25 5.25 5.25ZM7.75 5.25C8.09518 5.25 8.375 5.52982 8.375 5.875V9.625C8.375 9.97018 8.09518 10.25 7.75 10.25C7.40482 10.25 7.125 9.97018 7.125 9.625V5.875C7.125 5.52982 7.40482 5.25 7.75 5.25Z"
        fill="#ED5A57"
      />
    </svg>
  );
};

export default DeleteTemplate;
