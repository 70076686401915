import React, { createContext, useContext, useReducer, useMemo } from 'react';

import PropTypes from 'prop-types';
import { jobReducer } from './reducer';

const JobsContext = createContext();

const initialValues = {
  formValues: {
    jobTitle: '',
    companyName: '',
    companyLink: '',
    location: '',
    shortDescription: '',
    linkToApply: '',
  },
  isFileUpload: false,
  companyLogo: {
    name: '',
    url: '',
  },
  detailedDescription: '',
  isFormValid: false,
  formErrors: { companyLink: '', location: '' },
};

export const JobsWrapperContext = ({ children }) => {
  // const [state, setState] = useState(initialValues);

  const [state, dispatch] = useReducer(jobReducer, initialValues);

  const fileUpload = value => {
    dispatch({ type: 'FILE_UPLOAD', payload: value });
  };

  const handleChange = value => {
    dispatch({ type: 'FORM_CHANGES', payload: value });
  };

  const selectCompanyLogo = value => {
    dispatch({ type: 'COMPANY_LOGO_SELECT', payload: value });
  };

  const handleDetailedDescription = value => {
    dispatch({ type: 'DETAILED_DESC', payload: value });
  };

  const toggleIsFormValid = isValid =>
    dispatch({ type: 'TOGGLE_FORM_VALIDATION', payload: isValid });

  const setFormError = value => dispatch({ type: 'SET_FORM_ERROR', payload: value });

  const memoizedValue = useMemo(
    () => ({
      ...state,
      handleChange,
      fileUpload,
      selectCompanyLogo,
      handleDetailedDescription,
      toggleIsFormValid,
      setFormError,
    }),
    [state, handleChange, fileUpload, selectCompanyLogo, handleDetailedDescription, setFormError]
  );

  return <JobsContext.Provider value={memoizedValue}>{children}</JobsContext.Provider>;
};

JobsWrapperContext.propTypes = {
  children: PropTypes.any,
};

export const useJobContext = () => useContext(JobsContext);

export { JobsContext };
