import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authReducer } from './reducer';

const AuthContext = createContext();

const initialState = {
  isLoading: false,
  user: {
    username: '',
    token: '',
    profileUrl: '',
  },
  errorMsg: '',
};
export const AuthenticationContext = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const loginUser = user => {
    dispatch({ type: 'LOGIN_USER', payload: user });
  };

  const loginError = () => {
    dispatch({ type: 'LOGIN_ERROR', payload: 'Error in Loggin' });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };
  const values = { ...state, loginUser, loginError, logout };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

AuthenticationContext.propTypes = {
  children: PropTypes.node,
};

export const useAuthContext = () => useContext(AuthContext);

export { AuthContext };
