import React from 'react';

const ShareLogo = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9992 4.8C11.4865 4.80002 11.9623 4.65168 12.3632 4.37472C12.7642 4.09776 13.0714 3.7053 13.2439 3.24953C13.4164 2.79375 13.446 2.29627 13.3289 1.82323C13.2118 1.35019 12.9534 0.924008 12.5882 0.601361C12.223 0.278715 11.7682 0.0748925 11.2844 0.0169985C10.8005 -0.0408956 10.3105 0.0498823 9.87943 0.27726C9.44841 0.504638 9.09681 0.857842 8.87141 1.2899C8.646 1.72196 8.55746 2.2124 8.61756 2.696L4.66556 4.672C4.32655 4.34508 3.89884 4.12514 3.43572 4.03956C2.97259 3.95398 2.49453 4.00655 2.06107 4.19072C1.62761 4.3749 1.25792 4.68253 0.998048 5.07531C0.738176 5.46808 0.599609 5.92864 0.599609 6.3996C0.599609 6.87056 0.738176 7.33112 0.998048 7.72389C1.25792 8.11667 1.62761 8.42431 2.06107 8.60848C2.49453 8.79265 2.97259 8.84523 3.43572 8.75965C3.89884 8.67407 4.32655 8.45412 4.66556 8.1272L8.61756 10.1032C8.54756 10.665 8.67841 11.2334 8.98699 11.7081C9.29556 12.1827 9.762 12.533 10.3039 12.697C10.8457 12.861 11.4281 12.8282 11.9481 12.6044C12.4681 12.3805 12.8922 11.9801 13.1455 11.4738C13.3988 10.9674 13.465 10.3879 13.3323 9.83752C13.1996 9.28715 12.8767 8.8014 12.4205 8.46611C11.9643 8.13083 11.4043 7.9676 10.8394 8.00528C10.2745 8.04295 9.74117 8.27911 9.33356 8.672L5.38156 6.696C5.40577 6.49941 5.40577 6.30059 5.38156 6.104L9.33356 4.128C9.76396 4.544 10.3512 4.8 10.9992 4.8Z"
        fill="#4E4B66"
      />
    </svg>
  );
};

export default ShareLogo;
