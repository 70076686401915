import axios from 'axios';

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('profileUrl');
  localStorage.removeItem('accountid');
};

export async function validateToken(token) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/current`,
      config
    );
    return result;
  } catch (error) {
    console.log(error);
    return { status: error.response.status, data: error.response.data };
  }
}
