import React from 'react';
// import PropTypes from 'prop-types';

const NurtureboxIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.86984 0C0.389457 0 0 0.365497 0 0.816327V15.1837C0 15.6345 0.389457 16 0.86984 16C1.35022 16 1.73968 15.6345 1.73968 15.1837V0.816327C1.73968 0.365497 1.35022 0 0.86984 0Z"
        fill="#222B52"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56652 4.7349H11.1051C11.4094 4.7349 11.656 4.50345 11.656 4.2179V1.66007C11.656 1.37452 11.4094 1.14307 11.1051 1.14307H3.56652C3.26225 1.14307 3.01562 1.37452 3.01562 1.66007V4.2179C3.01562 4.50345 3.26225 4.7349 3.56652 4.7349Z"
        fill="#034ACF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8744 6.20447H3.56652C3.26225 6.20447 3.01562 6.43592 3.01562 6.72147V9.2793C3.01562 9.56485 3.26225 9.7963 3.56652 9.7963H14.8744C15.1787 9.7963 15.4253 9.56485 15.4253 9.2793V6.72147C15.4253 6.43592 15.1787 6.20447 14.8744 6.20447Z"
        fill="#034ACF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65541 11.2649H3.56652C3.26225 11.2649 3.01562 11.4963 3.01562 11.7819V14.3397C3.01562 14.6253 3.26225 14.8567 3.56652 14.8567H9.65541C9.95968 14.8567 10.2063 14.6253 10.2063 14.3397V11.7819C10.2063 11.4963 9.95968 11.2649 9.65541 11.2649Z"
        fill="#1ECCFF"
      />
    </svg>
  );
};

NurtureboxIcon.propTypes = {};

export default NurtureboxIcon;
