import React from 'react';
import styled from 'styled-components';
import { theme } from '../styles/GlobalStyles';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 50%;
  border: ${({ borderWidth }) => `${borderWidth} solid #edeef6`};
  border-top: ${({ ringColor, borderWidth }) => `${borderWidth} solid ${ringColor}`};
  animation: spin 2s linear infinite;
  color: blue;
  letter-spacing: 2px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ width, height, borderWidth }) => {
  return (
    <Wrapper
      ringColor={theme.primaryBlue}
      width={width}
      height={height}
      borderWidth={borderWidth}></Wrapper>
  );
};

Loader.defaultProps = {
  width: '8rem',
  height: '8rem',
  borderWidth: '14px',
};

Loader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.string,
};

export default Loader;
