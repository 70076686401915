import { useEffect } from 'react';

const useAutosizeTextarea = (textareaRef, value) => {
  useEffect(() => {
    if (textareaRef) {
      const scrollHeight = textareaRef.current.scrollHeight;

      // Setting the height of the Textarea based on the Users input
      textareaRef.current.style.height = `clamp(50px, ${scrollHeight}px, 150px)`;
    }
  }, [textareaRef, value]);
};

export default useAutosizeTextarea;
