import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

const RequireAuth = ({ isTokenValid }) => {
  const location = useLocation();

  return isTokenValid ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

RequireAuth.propTypes = {
  isTokenValid: PropTypes.bool,
};
export default RequireAuth;
