const jobReducer = (state, action) => {
  switch (action.type) {
    case 'FILE_UPLOAD':
      return {
        ...state,
        isFileUpload: action.payload.isFileUpload,
        detailedDescription: action.payload.detailedDescription,
      };

    case 'FORM_CHANGES': {
      let company = action.payload.companyLink;
      let link = action.payload.linkToApply;

      if (company || link) {
        if (!company.includes('http')) {
          company = `https://${company}`;
        }
        if (!link.includes('http')) {
          link = `https://${link}`;
        }
        company = company === 'https://' ? '' : company;
        link = link === 'https://' ? '' : link;
      }

      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload,
          companyLink: company,
          linkToApply: link,
        },
      };
    }
    case 'COMPANY_LOGO_SELECT':
      return { ...state, companyLogo: action.payload };

    case 'DETAILED_DESC': {
      return { ...state, detailedDescription: action.payload.detailedDescription };
    }
    case 'TOGGLE_FORM_VALIDATION': {
      return { ...state, isFormValid: action.payload };
    }
    case 'SET_FORM_ERROR': {
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...action.payload,
        },
      };
    }
    default:
      throw new Error('No matching action found');
  }
};

export default jobReducer;
