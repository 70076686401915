import { createGlobalStyle } from 'styled-components';

export const theme = {
  backgroundColor: '#F7F8FB',
  boxShadow: '0px 4px 10px rgba(44, 90, 246, 0.23)',
  previewBoxShadow: '0px 1px 46px rgba(20, 20, 42, 0.07)',
  borderRadius: '10px',
  smallScreen: '1024px',
  bigScreen: '1440px',

  // Colors
  white: '#fff',
  teal: '#066480',
  red: '#D32E2A',
  black: '#14142A',
  black2: '#0E0E0E',
  buttonBorderColor: '#034ACF',

  // Blue color
  primaryBlue: '#2934D0',
  primaryBlue2: '#2935D0',
  blue3: '#034ACF',
  blue4: '#106FDF',

  // Grey
  grey1: '#4E4B66',
  grey2: '#6E7191',
  grey3: '#EDEEF6',
  grey4: '#B8B7C2',
  grey5: '#D9DBE9',
  disableGrey: '#4F5B71',
  greyBorder: 'rgb(217, 219, 233)',
  // Green
  green: '#00BA88',
  green2: '#11C978',
};
const GlobalStyles = createGlobalStyle`

   @import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;700&family=Inter:wght@300;400;500;700&display=swap');

    *{
        box-sizing: border-box;
    }

    body{
        /* background-color: ${({ theme }) => theme.backgroundColor}; */
        font-family: 'Inter', sans-serif;
        margin: 0;
        padding:0;
        height: 100vH;
    }

    img{
        max-width: 100%;
    }
    h1, h2, h3 {
        margin:0
    }
`;

export default GlobalStyles;
