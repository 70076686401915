import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

const EditJob = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

EditJob.propTypes = {
  jobId: PropTypes.string,
};

export default EditJob;
