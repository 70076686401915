import React from 'react';

const UserIcon = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="45" fill="#EAEAEA" />
      <path
        d="M59.8656 33.5379C59.8656 41.2805 53.5586 47.5757 45.7554 47.5757C37.9523 47.5757 31.6453 41.2805 31.6453 33.5379C31.6453 25.7952 37.9523 19.5 45.7554 19.5C53.5586 19.5 59.8656 25.7952 59.8656 33.5379Z"
        stroke="#8C8C8C"
        strokeWidth="5"
      />
      <path
        d="M70 73.9999C70 60.7869 59.031 50.0757 45.5 50.0757C31.969 50.0757 21 60.7869 21 73.9999"
        stroke="#8C8C8C"
        strokeWidth="5"
      />
    </svg>
  );
};

export default UserIcon;
