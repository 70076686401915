import * as Yup from 'yup';
import {
  COMPANY_MIN_LENGTH,
  COMPANY_LENGTH,
  JOBTITLE_LENGTH,
  LOCATION_LENGTH,
  SHORT_DESC_MIN_LENGTH,
  SHORT_DESC_LENGTH,
  LOCATION_MIN_LENGTH,
  JOBTITLE_MIN_LENGTH,
} from '../common/constant';

import { isAlphaCharacters } from '../common/helper';

const validationYup = () =>
  Yup.object({
    jobTitle: Yup.string()
      .required('Title cannot be empty')
      .min(JOBTITLE_MIN_LENGTH, 'Minimum 5 characters required')
      .max(JOBTITLE_LENGTH, `Must be ${JOBTITLE_LENGTH} characters or less`),
    location: Yup.string()
      .required('Location cannot be empty')
      .min(LOCATION_MIN_LENGTH, 'Minimum 3 characters required')
      .max(LOCATION_LENGTH, `Must be ${LOCATION_LENGTH} characters or less`)
      .test('location-validation', 'Location should be alphabets(a-z) only', isAlphaCharacters),
    companyName: Yup.string()
      .required('Company name cannot be empty')
      .min(COMPANY_MIN_LENGTH, 'Minimum 5 characters')
      .max(COMPANY_LENGTH, `Must be ${COMPANY_LENGTH} characters or less`),
    companyLink: Yup.string()
      .required('Company Link cannot be empty')
      .min(5, 'Minimum 5 characters expected'),
    // .test('company-check', 'Please enter valid URL', isValidUrl),
    shortDescription: Yup.string()
      .required('Short Description cannot be empty')
      .min(SHORT_DESC_MIN_LENGTH, 'Minimum 10 characters')
      .max(SHORT_DESC_LENGTH, `Maximum ${SHORT_DESC_LENGTH} characters or less`),
    linkToApply: Yup.string()
      .required('Link to apply cannot be empty')
      .min(5, 'Minimum 5 characters expected'),
    // .test('link-check', 'Enter valid URL', isValidUrl),
  });
export { validationYup };
