import React from 'react';
import PropTypes from 'prop-types';

const LightningIcon = ({ fill = '' }) => {
  const style = { fill: fill || '#106FDF' };

  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6607_1710)">
        <path
          d="M8.47485 1.78181C8.6271 1.82969 8.76011 1.9249 8.85453 2.05358C8.94895 2.18226 8.99986 2.33771 8.99985 2.49731V6.24731H11.9998C12.137 6.24725 12.2716 6.28482 12.3889 6.35593C12.5063 6.42704 12.6018 6.52897 12.6652 6.65062C12.7287 6.77227 12.7575 6.90899 12.7486 7.04589C12.7397 7.18279 12.6935 7.31463 12.6148 7.42706L7.36485 14.9271C7.27347 15.058 7.14272 15.1563 6.9916 15.2078C6.84048 15.2592 6.67689 15.2611 6.52462 15.2132C6.37235 15.1652 6.23935 15.07 6.14496 14.9412C6.05058 14.8125 5.99974 14.657 5.99985 14.4973V10.7473H2.99985C2.86266 10.7474 2.72808 10.7098 2.61076 10.6387C2.49344 10.5676 2.39787 10.4657 2.33445 10.344C2.27104 10.2224 2.2422 10.0856 2.25109 9.94874C2.25997 9.81184 2.30624 9.68 2.38485 9.56756L7.63485 2.06756C7.72635 1.9369 7.85714 1.83882 8.0082 1.78757C8.15926 1.73632 8.32272 1.73456 8.47485 1.78256V1.78181Z"
          fill="#106FDF"
          style={style}
        />
      </g>
      <defs>
        <clipPath id="clip0_6607_1710">
          <rect width="15" height="15" fill="white" transform="translate(0 0.99707)" />
        </clipPath>
      </defs>
    </svg>
  );
};

LightningIcon.propTypes = {
  fill: PropTypes.string,
};
export default LightningIcon;
