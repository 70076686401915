import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled, { ThemeProvider } from 'styled-components';

import GlobalStyles, { theme } from './styles/GlobalStyles';

import RequireAuth from './components/RequireAuth';
import Missing from './components/Missing';
import NavHeader from './components/NavHeader';
import Layout from './components/Layout';

import CreateJob from './pages/createJob';
import DisplayJobs from './pages/DisplayJobs/DisplayJobs';
import EditJob from './pages/EditJob';
import Login from './pages/Login';
import Logout from './pages/Logout';
// import useToken from './hooks/useToken';

const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 300px;
    top: 65px;
  }
  .Toastify__toast--error {
    border: 1px solid #d32e2a80;
    color: #d32e2a;
    background-color: #f9dfdf;
  }
  .Toastify__toast--success {
    background-color: #dff9ec;
    border: 1px solid rgba(0, 186, 136, 0.5);
    color: #00ba88;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast {
    border-radius: 10px;
    margin: 0;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;

    &.Toastify__toast-body {
      margin: 0;
    }
  }

  .Toastify__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
function App() {
  const queryClient = new QueryClient();
  const search = useLocation();
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    checkExtensionIsLoggedIn();
  }, []);

  function checkExtensionIsLoggedIn() {
    try {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        { message: 'IS_EXTENSION_LOGGEDIN' },
        function (reply) {
          if (!reply || !reply.accountId || !reply.token) {
            setIsTokenValid(false);
            return;
          }
          localStorage.setItem('token', reply.token);
          localStorage.setItem('accountid', reply.accountId);
          setIsTokenValid(true);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  // const processedToken = useToken();

  // useEffect(() => {
  //   if (processedToken !== null) {
  //     if (!processedToken) {
  //       setIsTokenValid(false);
  //     } else {
  //       setIsTokenValid(true);
  //     }
  //   }
  // }, [processedToken]);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <StyledContainer
          closeButton={true}
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
        />
        {search.pathname !== '/jd' && <NavHeader />}
        <Routes>
            <Route element={<Layout />}>
              <Route exact path="/login" element={<Login />} />
              <Route element={<RequireAuth isTokenValid={isTokenValid} />}>
                <Route path="/" element={<DisplayJobs />} />
                <Route exact path="/create-job" element={<CreateJob />} />
                <Route path="/edit-job/:jobId" element={<EditJob />}>
                  <Route path="" element={<CreateJob />} />
                </Route>
                <Route path="/logout" element={<Logout/>}/>
              </Route>
              {/* Catch all */}
              <Route path="*" element={<Missing />} />
            </Route>
          </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
