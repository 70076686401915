import React from 'react';
import PropTypes from 'prop-types';

const ShareIcon = ({ fill = '' }) => {
  const styles = { fill: fill || 'white' };
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.49951 4.5999C9.92592 4.59992 10.3422 4.47012 10.6931 4.22778C11.0439 3.98544 11.3127 3.64204 11.4636 3.24324C11.6146 2.84444 11.6405 2.40914 11.538 1.99523C11.4356 1.58132 11.2095 1.20841 10.8899 0.926093C10.5704 0.643778 10.1724 0.465433 9.74906 0.414776C9.32567 0.364119 8.89689 0.443549 8.51975 0.642505C8.1426 0.841461 7.83496 1.15051 7.63772 1.52857C7.44049 1.90662 7.36302 2.33575 7.41561 2.7589L3.95761 4.4879C3.66097 4.20185 3.28673 4.0094 2.8815 3.93451C2.47626 3.85963 2.05796 3.90563 1.67868 4.06679C1.2994 4.22794 0.975924 4.49712 0.748536 4.8408C0.521148 5.18448 0.399902 5.58746 0.399902 5.99955C0.399902 6.41165 0.521148 6.81463 0.748536 7.15831C0.975924 7.50199 1.2994 7.77117 1.67868 7.93232C2.05796 8.09348 2.47626 8.13947 2.8815 8.06459C3.28673 7.98971 3.66097 7.79726 3.95761 7.5112L7.41561 9.2402C7.35436 9.73177 7.46885 10.2292 7.73886 10.6445C8.00886 11.0598 8.41699 11.3663 8.89112 11.5098C9.36524 11.6533 9.87484 11.6246 10.3298 11.4287C10.7848 11.2329 11.1559 10.8825 11.3776 10.4394C11.5992 9.99641 11.6571 9.4893 11.541 9.00773C11.4249 8.52616 11.1423 8.10112 10.7432 7.80775C10.344 7.51438 9.85401 7.37156 9.35974 7.40452C8.86547 7.43749 8.39877 7.64412 8.04211 7.9879L4.58411 6.2589C4.6053 6.08689 4.6053 5.91292 4.58411 5.7409L8.04211 4.0119C8.41871 4.3759 8.93251 4.5999 9.49951 4.5999Z"
        fill="white"
        style={styles}
      />
    </svg>
  );
};

ShareIcon.propTypes = {
  fill: PropTypes.string,
};

export default ShareIcon;
