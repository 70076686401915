import React, { useState } from 'react'
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { Flex } from '../../../styles/Flex.styled';
import { BackIcon, RightIcon } from '../../../components/icons';

const PreviousIcon = () => {
  return <Flex jc="center" gap=".15rem" ai="center">
    <BackIcon />
    Prev
  </Flex>
}

const NextIcon = () => {
  return <Flex jc="center" gap=".15rem" ai="center">
    <span>Next</span>
    <RightIcon />
  </Flex>
}
const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: 'active', // default to "selected"
})`
  margin: 1rem;
  margin-right:0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0;
  gap:.5rem;
  font-weight: 400;

  li a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 0.1rem 1rem;
    border: 1px solid #C0C3EE;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    // background-color:#fff;
    padding:7px 15px;
    font-weight:400;
    color:#14142A;
    font-size:14px;
  }
  li.previous a,
  li.next a{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height:40px;
    color:#14142A;
    font-size:14px;
    font-weight:400;
    gap: 0.5rem;
  }

  li.break a {
    font-family: inherit;
    // background-color: #fff;
    color:#14142A;
    font-weight: 400;
    font-size: 14px;
    border-color: transparent;
  }
  li.active a {
    background-color: #2934D0;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li a:active {
    background-color: #2934D0;

  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
const Paginate = ({ itemsPerPage = 6, totalCount, pageChangeHandler, setCurrentPage, currentPage }) => {
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  endOffset;
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    let page = event.selected;
    const newOffset = (event.selected * itemsPerPage) % totalCount;

    setItemOffset(newOffset);
    setCurrentPage(page);
    const skipFactor = (page * itemsPerPage);
    pageChangeHandler(skipFactor);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <MyPaginate
        breakLabel="..."
        nextLabel={<NextIcon />}
        previousLabel={<PreviousIcon />}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        forcePage={currentPage}
      />
    </>
  )
}

Paginate.propTypes = {
  itemsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  pageChangeHandler: PropTypes.func,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number
}

export default Paginate