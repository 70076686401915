import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Para } from '../styles/Flex.styled';
import { Link } from 'react-router-dom';

const Image = styled.img``;
const Title = styled.h1`
  margin-top: ${({ marginTop }) => marginTop};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const LinkButton = styled.span`
  & a {
    text-decoration: none;
    color: blue;
  }
`;

const NoJobsFound = ({ imgUrl, para, title }) => {
  return (
    <Flex jc="center" height={`calc(100vh - 235px)`}>
      <Flex direction="column nowrap" gap=".5rem" jc="center" width="400px">
        <Image src={imgUrl} alt="No results image" />
        <Title marginTop="1rem" fontWeight="600">
          {title}
        </Title>
        <Para fontWeight="400" textAlign="center">
          {para}{' '}
          <LinkButton>
            <Link to={'/create-job'}>Create JD</Link> button
          </LinkButton>
        </Para>
      </Flex>
    </Flex>
  );
};

NoJobsFound.propTypes = {
  imgUrl: PropTypes.string,
  para: PropTypes.string,
  title: PropTypes.string,
};

export default NoJobsFound;
