import React from 'react';
import styled from 'styled-components';
import nurtureBoxLogo from '../assets/icons/nurtureBoxLogo.svg';
import { useAuthContext } from '../context';
import { theme } from '../styles/GlobalStyles';
import { NavLink, useLocation } from 'react-router-dom';

const Header = styled.header`
  background-color: #2934d0;
  border-bottom: 1px solid #d9dbe9;
  box-sizing: border-box;
  color: #fff;

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 10px 2rem;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 75rem;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.bigScreen}) {
    width: 75rem;
  }

  @media (max-width: ${({ theme }) => theme.smallScreen}) {
    width: 60rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

const Username = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding-left: 10px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserIcon = styled.img`
  height: 36px;
  width: 36px;
  border-radius: 50%;
`;

const Image = styled.img``;

const NavHeader = () => {
  const search = useLocation();
  const { user } = useAuthContext();

  return (
    <Header>
      <Wrapper theme={theme}>
        <LogoContainer>
          {search.pathname === '/' ? (
            <Image src={nurtureBoxLogo} />
          ) : (
            <NavLink to="/">
              <Image src={nurtureBoxLogo} />
            </NavLink>
          )}
        </LogoContainer>
        {user?.profileUrl && (
          <UserWrapper>
            <UserIcon src={user?.profileUrl} />
            <Username>{user?.username}</Username>
          </UserWrapper>
        )}
      </Wrapper>
    </Header>
  );
};

export default NavHeader;
