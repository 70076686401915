import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ cursor, clickHandler }) => {
  const styles = { cursor: cursor || 'pointer' };

  const closeHandler = () => {
    clickHandler();
  };
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292787 1.29223C0.480314 1.10476 0.734622 0.99944 0.999786 0.99944C1.26495 0.99944 1.51926 1.10476 1.70679 1.29223L5.99979 5.58523L10.2928 1.29223C10.385 1.19672 10.4954 1.12053 10.6174 1.06813C10.7394 1.01572 10.8706 0.98813 11.0034 0.986976C11.1362 0.985822 11.2678 1.01112 11.3907 1.0614C11.5136 1.11169 11.6253 1.18594 11.7192 1.27983C11.8131 1.37372 11.8873 1.48538 11.9376 1.60827C11.9879 1.73117 12.0132 1.86285 12.012 1.99563C12.0109 2.12841 11.9833 2.25963 11.9309 2.38163C11.8785 2.50364 11.8023 2.61398 11.7068 2.70623L7.41379 6.99923L11.7068 11.2922C11.8889 11.4808 11.9897 11.7334 11.9875 11.9956C11.9852 12.2578 11.88 12.5086 11.6946 12.694C11.5092 12.8795 11.2584 12.9846 10.9962 12.9869C10.734 12.9892 10.4814 12.8884 10.2928 12.7062L5.99979 8.41323L1.70679 12.7062C1.51818 12.8884 1.26558 12.9892 1.00339 12.9869C0.741188 12.9846 0.490376 12.8795 0.304968 12.694C0.11956 12.5086 0.0143906 12.2578 0.0121121 11.9956C0.00983372 11.7334 0.110629 11.4808 0.292787 11.2922L4.58579 6.99923L0.292787 2.70623C0.105316 2.5187 0 2.26439 0 1.99923C0 1.73406 0.105316 1.47975 0.292787 1.29223Z"
        fill="#4E4B66"
        style={styles}
        onClick={closeHandler}
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  cursor: PropTypes.string,
  clickHandler: PropTypes.func,
};

export default CloseIcon;
