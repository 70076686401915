import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CreateJobErrorImg from '../assets/icons/createJobError.svg';
import { theme } from '../styles/GlobalStyles';
import Button from './Button';

const BackDrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background: #00000026;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: calc(50% - 495px / 2);
  left: calc(50% - 454px / 2);
  height: 495px;
  width: 454px;
  background-color: #ffffff;
  padding: 50px 78px 50px;
  box-shadow: 0px 1px 46px rgba(20, 20, 42, 0.07);
  border-radius: 10px;
  text-align: center;
`;

const Errortitle = styled.h1`
  color: #d32e2a;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 16px;
`;

const Errormsg = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #14142a;
  border-radius: 2px;
  width: 248px;
  margin: 8px auto 28px;
`;
const CreateJobError = () => {
  const navigate = useNavigate();

  return (
    <>
      {ReactDOM.createPortal(
        <>
          <BackDrop />
          <ModalWrapper>
            <img src={CreateJobErrorImg}></img>
            <Errortitle>{'Oooops! This page isn’t working'}</Errortitle>
            <Errormsg>
              The page you’re looking for is temporarily unavailable. Please try again after
              sometime
            </Errormsg>
            <Button
              width="290px"
              height="36px"
              fontSize=".875rem"
              fontWeight="600"
              type="button"
              bg={theme.primaryBlue}
              color={theme.white}
              padding="12px 16px"
              clickHandler={() => {
                navigate('/');
              }}>
              Close
            </Button>
          </ModalWrapper>
        </>,
        document.getElementById('root')
      )}
    </>
  );
};

export default CreateJobError;
