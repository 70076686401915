import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import PropTypes from 'prop-types';

import { BackIcon, CloseIcon } from '../icons';
import { getJdLink } from '../../services/jobs.services';

import { Flex, Span } from '../../styles/Flex.styled';
import { theme } from '../../styles/GlobalStyles';

import CandidateCard from './components/CandidateCard';
import CopyCard from './components/CopyCard';
import Loader from '../Loader';

const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: ${({ color }) => color};
  margin: 0;
`;

const ShareJd = ({ title, jobId, closeHandler }) => {
  const [isUrlGenerate, setIsUrlGenerate] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [shortendUrl, setShortendUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateUrl = async candidate => {
    setIsLoading(true);
    const link = await getJdLink(jobId, candidate);
    setShortendUrl(link);
    setIsLoading(false);
  };

  const confirmUrlGeneration = value => {
    setIsUrlGenerate(prev => !prev);
    setCandidateName(value);

    // Generating Bitly URL  if the candidate name is available
    if (value) generateUrl(value);
  };

  const handleBack = () => {
    setIsUrlGenerate(prev => !prev);
  };
  return (
    <Modal>
      <Flex
        padding="1.5rem"
        borderRadius="10px"
        boxShadow="0px 1px 46px rgba(20, 20, 42, 0.07)"
        direction="column nowrap"
        backgroundColor={theme.white}
        ai={isLoading ? 'center' : 'flex-start'}
        jc={isLoading ? 'center' : 'space-between'}
        height={isLoading ? '200px' : 'auto'}
        width="370px"
        gap="1rem">
        {isLoading && <Loader width="5rem" height="5rem" borderWidth="5px" />}
        {!isLoading && (
          <>
            <Flex jc="space-between" width="100%" mb="8px">
              {isUrlGenerate ? (
                <Flex gap="2px" jc="center" cursor="pointer" onClick={handleBack}>
                  <BackIcon /> <Span>Back</Span>
                </Flex>
              ) : (
                <Title color={theme.color}>Share JD</Title>
              )}

              <Span id="close-share-jd" cursor="pointer" onClick={closeHandler}>
                <CloseIcon clickHandler={closeHandler} />
              </Span>
            </Flex>
            {!isUrlGenerate && <CandidateCard confirmUrlGeneration={confirmUrlGeneration} />}
            {isUrlGenerate && (
              <CopyCard title={title} candidateName={candidateName} shortendUrl={shortendUrl} />
            )}
          </>
        )}
      </Flex>
    </Modal>
  );
};

ShareJd.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  jobId: PropTypes.string,
  title: PropTypes.string,
};

export default ShareJd;
