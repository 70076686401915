import React from 'react';
// import PropTypes from 'prop-types';

const NurtureboxLogo = () => {
  return (
    <svg width="75" height="12" viewBox="0 0 75 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.94593 11.7538V7.62051C5.94593 6.97823 5.84604 6.51725 5.64626 6.23755C5.45699 5.94749 5.13103 5.80246 4.66838 5.80246C4.17419 5.80246 3.81142 5.94749 3.5801 6.23755C3.35929 6.52761 3.24888 6.97823 3.24888 7.58943V11.7538H0.961914V3.81349H3.24888V4.54381C3.76411 3.93262 4.49489 3.62702 5.44122 3.62702C6.18777 3.62702 6.83969 3.8601 7.39697 4.32627C7.95426 4.79244 8.2329 5.54866 8.2329 6.59494V11.7538H5.94593Z"
        fill="#222B52"
      />
      <path
        d="M14.6434 11.7538V11.0235C14.1387 11.6347 13.4027 11.9403 12.4353 11.9403C12.1094 11.9403 11.7939 11.8885 11.489 11.7849C11.1841 11.6917 10.8897 11.5363 10.6058 11.3188C10.3219 11.1012 10.0905 10.7904 9.91177 10.3864C9.74354 9.98241 9.65942 9.51107 9.65942 8.97239V3.81349H11.9464V7.94682C11.9464 8.57874 12.041 9.03972 12.2303 9.32978C12.4196 9.61984 12.756 9.76487 13.2397 9.76487C13.7234 9.76487 14.0756 9.61984 14.2964 9.32978C14.5278 9.03972 14.6434 8.58909 14.6434 7.9779V3.81349H16.9304V11.7538H14.6434Z"
        fill="#222B52"
      />
      <path
        d="M18.5146 11.7538V3.81349H20.8016V4.52828C20.9068 4.32109 21.096 4.11909 21.3694 3.92226C21.6428 3.72544 21.9635 3.62702 22.3315 3.62702C22.9624 3.62702 23.4934 3.84975 23.9245 4.29519L23.6248 6.34632C23.1832 5.98375 22.7048 5.80246 22.1896 5.80246C21.2643 5.80246 20.8016 6.3515 20.8016 7.44958V11.7538H18.5146Z"
        fill="#222B52"
      />
      <path
        d="M26.9475 11.9403C25.5911 11.9403 24.9129 11.2152 24.9129 9.76487V5.78692H23.8088V3.81349H24.9129V1.60697L27.1841 0.53479V3.81349H28.9348V5.78692H27.1841V9.54732C27.1841 9.69235 27.2261 9.8063 27.3102 9.88918C27.4049 9.96169 27.5153 9.99795 27.6415 9.99795C28.1041 9.99795 28.5668 9.89436 29.0294 9.68717L28.777 11.5829C28.2829 11.8212 27.673 11.9403 26.9475 11.9403Z"
        fill="#222B52"
      />
      <path
        d="M35.4276 11.7538V11.0235C34.9229 11.6347 34.1869 11.9403 33.2195 11.9403C32.8936 11.9403 32.5781 11.8885 32.2732 11.7849C31.9683 11.6917 31.6738 11.5363 31.3899 11.3188C31.106 11.1012 30.8747 10.7904 30.696 10.3864C30.5277 9.98241 30.4436 9.51107 30.4436 8.97239V3.81349H32.7306V7.94682C32.7306 8.57874 32.8252 9.03972 33.0145 9.32978C33.2037 9.61984 33.5402 9.76487 34.0239 9.76487C34.5076 9.76487 34.8598 9.61984 35.0806 9.32978C35.312 9.03972 35.4276 8.58909 35.4276 7.9779V3.81349H37.7146V11.7538H35.4276Z"
        fill="#222B52"
      />
      <path
        d="M39.2988 11.7538V3.81349H41.5858V4.52828C41.6909 4.32109 41.8802 4.11909 42.1536 3.92226C42.427 3.72544 42.7477 3.62702 43.1157 3.62702C43.7466 3.62702 44.2776 3.84975 44.7087 4.29519L44.409 6.34632C43.9674 5.98375 43.489 5.80246 42.9738 5.80246C42.0485 5.80246 41.5858 6.3515 41.5858 7.44958V11.7538H39.2988Z"
        fill="#222B52"
      />
      <path
        d="M48.3714 11.9403C47.1832 11.9403 46.2474 11.5726 45.564 10.837C44.8805 10.1015 44.5388 9.08116 44.5388 7.77589C44.5388 6.50171 44.8805 5.49168 45.564 4.74582C46.2579 3.99995 47.1938 3.62702 48.3714 3.62702C49.4439 3.62702 50.3167 3.94816 50.9896 4.59043C51.6625 5.2327 51.999 6.16503 51.999 7.38742C51.999 7.89503 51.978 8.2835 51.9359 8.55284H46.7784C46.7995 9.05008 46.9572 9.42301 47.2516 9.67163C47.546 9.9099 47.9193 10.029 48.3714 10.029C49.1075 10.029 49.7121 9.74933 50.1852 9.18993L51.5101 10.4952C50.7004 11.4586 49.6542 11.9403 48.3714 11.9403ZM46.7942 6.8591H49.8067C49.691 5.97857 49.1811 5.5383 48.2768 5.5383C47.8667 5.5383 47.5302 5.65743 47.2674 5.89569C47.0045 6.1236 46.8468 6.44473 46.7942 6.8591Z"
        fill="#222B52"
      />
      <path
        d="M56.6733 11.9403C55.9057 11.9403 55.217 11.6502 54.6071 11.0701V11.7538H53.2349V1.17188L54.6071 0.53479V4.4972C55.1434 3.91708 55.8163 3.62702 56.6259 3.62702C57.6354 3.62702 58.445 3.99478 59.0549 4.73028C59.6647 5.46579 59.9697 6.48099 59.9697 7.77589C59.9697 9.11224 59.6752 10.143 59.0864 10.8681C58.5081 11.5829 57.7037 11.9403 56.6733 11.9403ZM56.484 10.6817C57.1675 10.6817 57.6827 10.4279 58.0297 9.92026C58.3872 9.40229 58.5659 8.68751 58.5659 7.77589C58.5659 6.8332 58.3872 6.11324 58.0297 5.616C57.6827 5.11875 57.1675 4.87013 56.484 4.87013C56.0634 4.87013 55.6849 4.9789 55.3484 5.19645C55.0119 5.41399 54.7648 5.65743 54.6071 5.92677V9.60948C54.8069 9.94098 55.075 10.2051 55.4115 10.402C55.748 10.5884 56.1055 10.6817 56.484 10.6817Z"
        fill="#222B52"
      />
      <path
        d="M66.9965 10.837C66.3551 11.5726 65.4877 11.9403 64.3941 11.9403C63.3006 11.9403 62.4331 11.5726 61.7917 10.837C61.1503 10.1015 60.8296 9.08634 60.8296 7.79143C60.8296 6.49653 61.1503 5.48132 61.7917 4.74582C62.4331 3.99995 63.3006 3.62702 64.3941 3.62702C65.4877 3.62702 66.3551 3.99478 66.9965 4.73028C67.6379 5.46579 67.9586 6.48099 67.9586 7.77589C67.9586 9.0708 67.6379 10.0912 66.9965 10.837ZM62.7854 9.92026C63.1744 10.4279 63.7107 10.6817 64.3941 10.6817C65.0776 10.6817 65.6086 10.4279 65.9871 9.92026C66.3762 9.40229 66.5707 8.68751 66.5707 7.77589C66.5707 6.87464 66.3762 6.16503 65.9871 5.64707C65.6086 5.12911 65.0776 4.87013 64.3941 4.87013C63.7107 4.87013 63.1744 5.12911 62.7854 5.64707C62.4068 6.15468 62.2176 6.86946 62.2176 7.79143C62.2176 8.69269 62.4068 9.40229 62.7854 9.92026Z"
        fill="#222B52"
      />
      <path
        d="M68.0448 11.7538L70.7418 7.62051L68.2498 3.81349H69.7797L71.0573 5.74031C71.2991 6.1236 71.4516 6.3774 71.5147 6.50171C71.767 6.08734 71.9247 5.83354 71.9878 5.74031L73.2496 3.81349H74.7953L72.2875 7.62051L75.0003 11.7538H73.4546L71.9721 9.50071C71.8564 9.3246 71.7039 9.0708 71.5147 8.7393C71.42 8.92577 71.2676 9.17957 71.0573 9.50071L69.5747 11.7538H68.0448Z"
        fill="#222B52"
      />
    </svg>
  );
};

NurtureboxLogo.propTypes = {};

export default NurtureboxLogo;
