import { useState } from 'react';
import copy from 'copy-to-clipboard';

const useCopyToClipboard = (resetInterval = null) => {
  const [isCopied, setIsCopied] = useState(false);

  console.log(resetInterval);
  const handleCopy = text => {
    if (typeof text === 'string' || typeof text === 'number') {
      copy(text.toString());
      setIsCopied(true);
    } else {
      setIsCopied(false);
    }
  };

  return [isCopied, handleCopy];
};

export default useCopyToClipboard;
