import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logout } from '../services/auth.services';

const Logout = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!search.get('token')) {
      navigate('/');
      return;
    }
    if (search.get('token')) {
      const tk = search.get('token');
      logout();
      window.open(`${process.env.REACT_APP_AUTH_URL}/logout?token=${tk}`, '_self');
      return;
    }
    navigate('/');
  }, [search]);
  return <></>
};

export default Logout;
