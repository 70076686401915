import React from 'react';
import { AnchorTag, Flex, Para } from '../../../styles/Flex.styled';
import { theme } from '../../../styles/GlobalStyles';
import { NurtureboxIcon, NurtureboxLogo } from '../../../components/icons';
import PropTypes from 'prop-types';

const PreviewFooter = ({ linkToApply }) => {
  return (
    <>
      <Flex jc="center" ai="center" width="100%" mb="1rem">
        <AnchorTag
          href={linkToApply || ''}
          fontSize="14px"
          fontWeight="600"
          target="_blank"
          height="2.25rem"
          width="7.5rem"
          backgroundColor={linkToApply ? theme.blue4 : theme.grey2}
          color={theme.white}
          borderRadius="10px"
          pointerEvents={linkToApply ? 'auto' : 'none'}
          cursor={linkToApply ? 'pointer' : 'none'}>
          Apply now
        </AnchorTag>
      </Flex>
      <Flex direction="row nowrap" jc="space-between" width="100%" mb=".75rem">
        <Para fontSize=".75rem" fontWeight="300" lineHeight="29px">
          https://www.nurturebox.ai/
        </Para>
        <Flex gap=".25rem">
          <Para fontSize=".75rem" fontWeight="300" lineHeight="29px">
            Powered by{' '}
          </Para>
          <NurtureboxIcon />
          <NurtureboxLogo />
        </Flex>
      </Flex>
    </>
  );
};

PreviewFooter.defaulProps = {
  linkToApply: '',
};
PreviewFooter.propTypes = {
  linkToApply: PropTypes.string,
};

export default PreviewFooter;
