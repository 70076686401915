import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../styles/GlobalStyles';
import Button from '../components/Button';
import Modal from '../components/Modal';
import { CloseIcon } from '../components/icons';
import { Flex, Para, Span } from '../styles/Flex.styled';

const ConfirmationPopup = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #0e0e0e;
  width: 450px;
  gap: 1.375rem;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

const ConfirmationCard = ({ title, description, cancelHandler, actionHandler, btnText }) => {
  return (
    <Modal>
      {/*TODO: Construct default confirmation modal */}
      <ConfirmationPopup>
        <Flex jc="space-between">
          <Title>{title}</Title>
          <Span onClick={cancelHandler} cursor="pointer">
            <CloseIcon />
          </Span>
        </Flex>
        <Para fontWeight="400" fontSize="14px" color={theme.black2} lineHeight="22px">
          {description}
        </Para>
        <Flex jc="flex-end">
          <Button
            clickHandler={cancelHandler}
            color={theme.grey1}
            fontWeight="600"
            fontSize="1rem"
            height="36px"
            bg={theme.white}
            padding="10px 40px">
            Cancel
          </Button>
          {actionHandler && (
            <Button
              clickHandler={actionHandler}
              color={theme.white}
              bg={theme.red}
              fontSize="1rem"
              fontWeight="600"
              padding="10px 40px"
              height="36px"
              width="160px">
              {btnText}
            </Button>
          )}
        </Flex>
      </ConfirmationPopup>
    </Modal>
  );
};

ConfirmationCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired,
};

export default ConfirmationCard;
