import React from 'react';

const CopyTemplate = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 2.125C2.58424 2.125 2.42527 2.19085 2.30806 2.30806C2.19085 2.42527 2.125 2.58424 2.125 2.75V7.75C2.125 7.91576 2.19085 8.07473 2.30806 8.19194C2.42527 8.30915 2.58424 8.375 2.75 8.375H3.375V5.25C3.375 4.75272 3.57254 4.27581 3.92418 3.92418C4.27581 3.57254 4.75272 3.375 5.25 3.375H8.375V2.75C8.375 2.58424 8.30915 2.42527 8.19194 2.30806C8.07473 2.19085 7.91576 2.125 7.75 2.125H2.75ZM9.625 3.375V2.75C9.625 2.25272 9.42745 1.77581 9.07582 1.42417C8.72419 1.07254 8.24728 0.875 7.75 0.875H2.75C2.25272 0.875 1.77581 1.07254 1.42417 1.42417C1.07254 1.77581 0.875 2.25272 0.875 2.75V7.75C0.875 8.24728 1.07254 8.72419 1.42417 9.07582C1.77581 9.42745 2.25272 9.625 2.75 9.625H3.375V10.25C3.375 10.7473 3.57254 11.2242 3.92418 11.5758C4.27581 11.9275 4.75272 12.125 5.25 12.125H10.25C10.7473 12.125 11.2242 11.9275 11.5758 11.5758C11.9275 11.2242 12.125 10.7473 12.125 10.25V5.25C12.125 4.75272 11.9275 4.27581 11.5758 3.92418C11.2242 3.57254 10.7473 3.375 10.25 3.375H9.625ZM5.25 4.625C5.08424 4.625 4.92527 4.69085 4.80806 4.80806C4.69085 4.92527 4.625 5.08424 4.625 5.25V10.25C4.625 10.4158 4.69085 10.5747 4.80806 10.6919C4.92527 10.8092 5.08424 10.875 5.25 10.875H10.25C10.4158 10.875 10.5747 10.8092 10.6919 10.6919C10.8092 10.5747 10.875 10.4158 10.875 10.25V5.25C10.875 5.08424 10.8092 4.92527 10.6919 4.80806C10.5747 4.69085 10.4158 4.625 10.25 4.625H5.25Z"
        fill="white"
      />
    </svg>
  );
};

export default CopyTemplate;
