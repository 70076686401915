import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Flex, Para } from '../../../styles/Flex.styled';
import { theme } from '../../../styles/GlobalStyles';

import { Copy } from '../../icons';

import useCopyToClipboard from '../../../hooks/useCopyToClipboard';

const CopyText = ({ id, text, url, content, border, borderRadius, padding, callback }) => {
  const [isCopied, handleCopy] = useCopyToClipboard(3000);

  useEffect(() => callback(isCopied), [isCopied]);

  const extractMsg = content => {
    let msg = '';

    const msgContent = Array.from(content.current.children);
    const msgContentLength = msgContent.length - 1;

    for (let i = 0; i <= msgContentLength; i++) {
      let el = msgContent[i];

      if (el?.innerText) {
        msg += i === msgContentLength ? '\n' + el.innerText + '\n' : el.innerText + '\n';
      } else {
        msg += '\n' + ' ' + el.value + '\n';
      }
    }

    return msg;
  };

  const handleMsgRef = () => (url ? handleCopy(url) : handleCopy(extractMsg(content)));

  return (
    <Flex id={id}
      jc="flex-start"
      gap=".2rem"
      onClick={handleMsgRef}
      cursor="pointer"
      border={border}
      borderRadius={borderRadius}
      padding={padding}
      flex="2">
      <Copy />
      <Para color={theme.primaryBlue} fontSize=".75rem" fontWeight="500">
        {text}
      </Para>
    </Flex>
  );
};

CopyText.defaultProps = {
  border: '',
  padding: '',
  borderRadius: '',
  ur: undefined,
  content: undefined,
};

CopyText.propTypes = {
  id: PropTypes.string,
  content: PropTypes.object,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  padding: PropTypes.string,
  callback: PropTypes.func,
  text: PropTypes.string,
  url: PropTypes.string,
};

export default CopyText;
