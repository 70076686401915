import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import triggerOnClickOutside from '../common/hooks/useOnClickOutside';

const ModalWrapper = styled.div`
  background: #14142a;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  color: ${({ color, theme }) => color || theme.white};
  line-height: 18px;
  width: 210px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  z-index: 1;
  right: 17px;
  top: 45px;
  font-weight: 500;
`;

const ModalDescription = styled.div`
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  padding: 10px 0;
`;

const OptionLayer = styled.div`
  padding: 10px 15px;
  border-top: ${props =>
    props.label === 'Delete template' ? '7px solid rgba(237, 238, 246, 0.12)' : ''};
`;

const Option = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin: 0 !important;
`;

const Label = styled.span`
  margin-left: 8px;
`;

const OptionModal = ({ options, closeOptionsDropdown }) => {
  const optionsRef = useRef(null);
  triggerOnClickOutside(optionsRef, closeOptionsDropdown);

  return (
    <ModalWrapper ref={optionsRef}>
      {options &&
        options.length &&
        options.map(
          (option, index) =>
            !option.toHide && (
              <OptionLayer label={option.label} key={index}>
                <Option key={index} onClick={option.onClick}>
                  <div>
                    <span>{option.icon}</span>
                    <Label>{option.label}</Label>
                  </div>
                </Option>
                {option.description && (
                  <>
                    <ModalDescription>
                      <div>{option.description}</div>
                    </ModalDescription>
                  </>
                )}
              </OptionLayer>
            )
        )}
    </ModalWrapper>
  );
};

OptionModal.defaultProps = {
  pos: {},
};

OptionModal.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      label: PropTypes.string,
      onClick: PropTypes.func,
      className: PropTypes.string,
      isSelected: PropTypes.bool,
      toHide: PropTypes.bool,
    })
  ).isRequired,
  className: PropTypes.string,
  pos: PropTypes.object,
  closeOptionsDropdown: PropTypes.func.isRequired,
};

export default OptionModal;
