import React from 'react';

const ActiveJobLogo = () => {
  return (
    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.47533 0.784009C6.62759 0.831891 6.7606 0.927095 6.85502 1.05578C6.94944 1.18446 7.00034 1.3399 7.00033 1.49951V5.24951H10.0003C10.1375 5.24945 10.2721 5.28702 10.3894 5.35813C10.5067 5.42924 10.6023 5.53116 10.6657 5.65282C10.7291 5.77447 10.758 5.91118 10.7491 6.04808C10.7402 6.18498 10.6939 6.31683 10.6153 6.42926L5.36533 13.9293C5.27396 14.0602 5.14321 14.1585 4.99209 14.21C4.84097 14.2614 4.67738 14.2633 4.52511 14.2154C4.37284 14.1674 4.23983 14.0722 4.14545 13.9434C4.05107 13.8147 4.00023 13.6591 4.00033 13.4995V9.74951H1.00033C0.863145 9.74957 0.728567 9.712 0.611247 9.64089C0.493926 9.56978 0.398358 9.46785 0.334941 9.3462C0.271524 9.22455 0.24269 9.08784 0.251575 8.95093C0.26046 8.81403 0.306725 8.68219 0.385334 8.56976L5.63533 1.06976C5.72684 0.939099 5.85763 0.841018 6.00868 0.789766C6.15974 0.738514 6.32321 0.73676 6.47533 0.784759V0.784009Z"
        fill="#1ECCFF"
      />
    </svg>
  );
};

export default ActiveJobLogo;
