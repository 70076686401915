import React from 'react';
import PropTypes from 'prop-types';

const BackIcon = ({ clickHandler }) => {
  const handleBack = () => clickHandler();
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 15L7.5 10L12.5 5" stroke="#14142A" strokeWidth="2" onClick={handleBack} />
    </svg>
  );
};

BackIcon.propTypes = {
  clickHandler: PropTypes.func,
};

export default BackIcon;
