import React from 'react'

const RightIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 4L10.5 8L6.5 12" stroke="#14142A" strokeWidth="2" />
    </svg>

  )
}

export default RightIcon