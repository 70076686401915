import React from 'react';

const Copy = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 5.99988C4 5.46944 4.21071 4.96074 4.58579 4.58566C4.96086 4.21059 5.46957 3.99988 6 3.99988H12C12.5304 3.99988 13.0391 4.21059 13.4142 4.58566C13.7893 4.96074 14 5.46944 14 5.99988V11.9999C14 12.5303 13.7893 13.039 13.4142 13.4141C13.0391 13.7892 12.5304 13.9999 12 13.9999H6C5.46957 13.9999 4.96086 13.7892 4.58579 13.4141C4.21071 13.039 4 12.5303 4 11.9999V5.99988Z"
        fill="#2934D0"
      />
      <path
        d="M2 0.00183105C1.46957 0.00183105 0.960859 0.212545 0.585786 0.587617C0.210714 0.96269 0 1.4714 0 2.00183V8.00183C0 8.53226 0.210714 9.04097 0.585786 9.41604C0.960859 9.79112 1.46957 10.0018 2 10.0018V2.00183H10C10 1.4714 9.78929 0.96269 9.41421 0.587617C9.03914 0.212545 8.53043 0.00183105 8 0.00183105H2Z"
        fill="#2934D0"
      />
    </svg>
  );
};

export default Copy;
