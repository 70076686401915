import React from 'react';
import Button from '../components/Button';
import { Para } from '../styles/Flex.styled';
import styled from 'styled-components';

const ContentBody = styled('div')`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;


const Login = () => {
  const loginButtonClick = () => {
    window.open(`${process.env.REACT_APP_AUTH_URL}/login?app=personalizedjd`,'_self');
    // navigate(from, { replace: true });
  };

  return (
    <ContentBody>
      <Para variant="PrimaryText">Welcome to NurtureBoxJd</Para>
      <Para variant="SecondaryText">Please login to continue</Para>
      <Button type="button" clickHandler={loginButtonClick} height='30px'>
        Login
      </Button>
    </ContentBody>
  );
};

export default Login;
